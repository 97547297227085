import {
  getCandidateTemplate,
  getCandidateTemplates,
} from "@/features/candidate_templates/utils/api"
import {
  getContractTemplate,
  getContractTemplates,
} from "@/features/contract_templates/utils/api"
import { fieldsRegistry } from "@/features/fields/registry"
import { IField } from "@/features/fields/types"
import { Icon } from "@/features/icons/components/IconLoader"
import { getTaskTemplate, getTaskTemplates } from "@/features/templates/api"
import { ITemplate } from "@/features/templates/types"
import { DropdownGeneric } from "@/shared/components/fields"
import { ColorPickerMinimalInput } from "@/shared/components/inputs/ColorPickerMininal"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { cn, randomHexColor } from "@/utils/helpers"
import {
  faCopy,
  faTrashAlt,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { atom, useRecoilState } from "recoil"
import { TChartConfiguration, TSourceIndex } from "../../types"
import {
  ALLOWED_GROUP_KEYS,
  ALLOWED_TEMPLATE_FIELD_KEYS,
  DEFAULT_FIELDS,
  DEFAULT_GROUP_KEYS,
  SOURCE_TYPES,
} from "../../utils/constants"
import { CustomComponent } from "../InputComponent"

const templateUidsAtom = atom({
  key: "widgets",
  default: [],
})

export const ChartSettings = ({
  onChange,
  widget,
  settings,
  errorChecking,
}) => {
  const [_settings, setSettings] = useState<TChartConfiguration>(settings)
  const [datasourceTemplates, setDatasourceTemplates] = useState<ITemplate[]>()
  const [availableFields, setAvailableFields] = useState<IField[]>([])
  const [templateFields, setTemplateFields] =
    useState<Record<string, IField[]>>()
  const [templateUids, setTemplateUids] = useRecoilState(templateUidsAtom)

  useEffect(() => {
    setSettings(settings)
  }, [widget])

  useEffect(() => {
    const uids = _settings?.datasets?.map(
      (dataset) => dataset.template?.uid || "",
    )
    const uidsAreEqual = (a, b) =>
      a?.length === b?.length && a?.every((v, i) => v === b[i])
    if (uidsAreEqual(uids, templateUids)) return
    if (uids !== templateUids) setTemplateUids(uids)
  }, [_settings])

  const prepareAvailableFields = useCallback(async () => {
    if (!_settings?.datasets) return
    const bar = _settings?.datasets.map(async (d) => {
      if (!d.template?.uid) return
      const extendedTemplate = await getTaskTemplate(d.template?.uid)
      setTemplateFields((prev) => {
        return {
          ...prev,
          [d.template.uid]: extendedTemplate.fields,
        }
      })
      return extendedTemplate.fields
    })

    const _bar = await Promise.all(bar)

    const flatBar = _bar.flat().filter((obj) => obj !== undefined)
    const commonObjects = flatBar.filter((obj1) =>
      _bar.every(
        (array) =>
          Array.isArray(array) && array.some((obj2) => obj1.uid === obj2.uid),
      ),
    )

    const seenUids = new Set<string>()
    const uniqueObjects = commonObjects.filter((obj) => {
      if (seenUids.has(obj.uid)) {
        return false
      }
      seenUids.add(obj.uid)
      return true
    })

    setAvailableFields(uniqueObjects)
  }, [templateUids])

  const calculateUniqueFields = useCallback(async () => {
    if (!_settings?.datasets) return
    const datasetsFields = _settings?.datasets.map(async (d) => {
      if (!d.template?.uid) return
      let extendedTemplate
      switch (_settings?.sourcetype) {
        case "tasks":
        case "rfqs": {
          extendedTemplate = await getTaskTemplate(d.template.uid)
          break
        }
        case "contracts": {
          extendedTemplate = await getContractTemplate(d.template.uid)
          break
        }
        case "talents": {
          extendedTemplate = await getCandidateTemplate(d.template.uid)
          break
        }
      }
      setTemplateFields((prev) => {
        return {
          ...prev,
          [d.template.uid]: (extendedTemplate?.fields ?? []).filter((field) =>
            ALLOWED_TEMPLATE_FIELD_KEYS[_settings?.sourcetype].includes(
              field.type,
            ),
          ),
        }
      })
      return extendedTemplate.fields
    })

    await Promise.all(datasetsFields)
  }, [templateUids])

  useEffect(() => {
    prepareAvailableFields()
    calculateUniqueFields()
  }, [templateUids])

  useEffect(() => {
    onChange?.(_settings)
  }, [_settings])

  const handleChanges = (key: string) => (value: any) => {
    setSettings((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const handleRemoveDataset = (index: number) => {
    setSettings((prev) => {
      return {
        ...prev,
        datasets: prev.datasets
          ? prev.datasets.filter((_, i) => i !== index)
          : [],
      }
    })
  }

  const handleTemplateChange = async (type: TSourceIndex) => {
    switch (type) {
      case "tasks":
      case "rfqs":
        setSettings({
          ..._settings,
          sourcetype: type as any,
        })
        await getTaskTemplates().then((data) => {
          if (type === "rfqs") {
            const minibidTemplates = data.filter(
              (template) => template.is_minibid,
            )
            setDatasourceTemplates(minibidTemplates)
          } else {
            const taskTemplates = data.filter(
              (template) => !template.is_minibid,
            )
            setDatasourceTemplates(taskTemplates)
          }
        })
        break
      case "contracts":
        setSettings({
          ..._settings,
          sourcetype: "contracts",
        })
        await getContractTemplates().then((data) => {
          setDatasourceTemplates(data)
        })
        break

      case "talents":
        setSettings({
          ..._settings,
          sourcetype: "talents",
        })
        await getCandidateTemplates().then((data) => {
          setDatasourceTemplates(data)
        })
        break

      default:
        break
    }
  }

  useEffect(() => {
    handleTemplateChange(_settings?.sourcetype)
  }, [_settings?.sourcetype])

  const duplicateDataset = (index: number) => {
    const dataset = _settings?.datasets[index]
    if (!dataset) return
    const clone = { ...dataset, color: randomHexColor() }
    setSettings((prev) => {
      return {
        ...prev,
        datasets: [...prev.datasets, clone],
      }
    })
  }

  return (
    <div className="my-3 flex flex-col gap-2">
      <div className="text-md my-2 font-medium">Datasets</div>
      <DropdownGeneric
        collection={SOURCE_TYPES}
        renderItem={({ item }) => (
          <DropdownItem
            className={cn("m-1 text-xs")}
            title={item.name}
            onClick={() => {
              handleChanges("datasource")(item.value)
              handleTemplateChange(item.value)
            }}
            icon={item.icon}
          />
        )}
        disabled={false}
        button={CustomComponent({
          value: _settings?.sourcetype || "",
          label: "Source",
          error:
            !_settings?.sourcetype &&
            _settings?.datasets &&
            _settings?.datasets.length > 0
              ? "Source is required"
              : undefined,
        })}
      />
      <div className="max-h-[35rem] overflow-auto">
        <div className="grid grid-cols-3 gap-2">
          <>
            {_settings?.datasets?.map((_, index) => {
              const dataset = _settings?.["datasets"]?.[index]
              const updateSetting = (index, key, value) => {
                if (!_settings?.["datasets"]) return
                setSettings((prevSettings) => {
                  if (!prevSettings.datasets) {
                    return prevSettings
                  }
                  return {
                    ...prevSettings,
                    datasets: prevSettings.datasets.map((d, i) =>
                      i === index
                        ? {
                            ...d,
                            [key]: value,
                          }
                        : d,
                    ),
                  }
                })
              }
              return (
                <div
                  key={index}
                  className="flex flex-col items-center space-y-3 rounded-md border p-3 text-gray-500"
                >
                  <div className="flex w-full items-center justify-between gap-2">
                    <div className="cursor-default font-medium">
                      {dataset?.name || "Dataset"}
                    </div>
                    <div className="flex items-center gap-2">
                      <Icon
                        icon={faCopy}
                        className="text-custom-gray-dark cursor-pointer hover:text-blue-500"
                        size="sm"
                        onClick={() => duplicateDataset(index)}
                      />
                      <Icon
                        icon={faTrashAlt}
                        className="text-custom-gray-dark hover:text-custom-red cursor-pointer"
                        size="sm"
                        onClick={() => handleRemoveDataset(index)}
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-3">
                    <div
                      className={cn(
                        "border-custom-gray group relative flex items-center rounded-md border bg-white px-4 py-2 ring-inset group-focus:ring-2",
                        errorChecking &&
                          !dataset?.name &&
                          "border-red-500 text-red-500",
                      )}
                    >
                      <input
                        value={dataset?.name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          updateSetting(index, "name", e.target.value)
                        }
                        className={cn(
                          "w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
                        )}
                      />
                      <span
                        className={cn(
                          "pointer-events-none absolute ml-0.5 transform bg-inherit text-sm leading-none transition-all",
                          dataset?.name &&
                            "mx-0 -translate-x-2.5 -translate-y-5 px-0.5 text-xs text-gray-400",
                        )}
                      >
                        Dataset name
                      </span>
                      <ColorPickerMinimalInput
                        color={dataset?.color}
                        onSelect={(color) =>
                          updateSetting(index, "color", color)
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <DropdownGeneric
                        collection={datasourceTemplates || []}
                        renderItem={({ item }) => (
                          <DropdownItem
                            className={cn("m-1 text-xs")}
                            title={item.name}
                            onClick={() => {
                              updateSetting(index, "ykey", {
                                label: "",
                                uid: "",
                              })
                              updateSetting(index, "ykeystate", {
                                label: "",
                                uid: "",
                              })
                              updateSetting(index, "aggregationtype", {
                                label: "",
                                uid: "",
                              })
                              updateSetting(index, "template", {
                                label: item.name,
                                uid: item.uid,
                              })
                            }}
                          />
                        )}
                        button={CustomComponent({
                          value: dataset?.template?.label || "",
                          label: "Template",
                          error:
                            errorChecking && !dataset?.template?.label
                              ? "required"
                              : undefined,
                          onCancel: () => {
                            updateSetting(index, "xkey", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "template", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "ykey", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "ykeystate", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "aggregationtype", "")
                          },
                        })}
                      />
                      <DropdownGeneric
                        collection={
                          [
                            ...(DEFAULT_GROUP_KEYS[settings?.sourcetype] ?? []),
                            ...availableFields,
                          ].filter((field) =>
                            ALLOWED_GROUP_KEYS[
                              settings?.sourcetype
                            ]?.includes?.(field.type),
                          ) || []
                        }
                        renderItem={({ item }) => (
                          <DropdownItem
                            className={cn("m-1 text-xs")}
                            title={item?.label || "Select field"}
                            onClick={() => {
                              _settings.datasets?.forEach((d, i) => {
                                updateSetting(i, "xkey", {
                                  label: item?.label,
                                  uid: item?.uid,
                                })
                              })
                              updateSetting(index, "xkey", {
                                label: item?.label,
                                uid: item?.uid,
                              })
                            }}
                            selected
                          />
                        )}
                        button={CustomComponent({
                          value: dataset?.xkey?.label || "",
                          label: "X key",
                          error:
                            errorChecking && !dataset?.xkey?.label
                              ? "required"
                              : undefined,
                          onCancel: () =>
                            updateSetting(index, "xkey", {
                              label: "",
                              uid: "",
                            }),
                        })}
                      />
                      <DropdownGeneric
                        collection={[
                          ...(DEFAULT_FIELDS[settings?.sourcetype] || []),
                          ...(templateFields?.[dataset?.template?.uid] || []),
                        ].filter((field) =>
                          ALLOWED_TEMPLATE_FIELD_KEYS[
                            settings?.sourcetype
                          ]?.includes?.(field.type),
                        )}
                        renderItem={({ item }) => {
                          const field = fieldsRegistry.find(
                            (f) => f.key === item?.type,
                          )
                          return (
                            <DropdownItem
                              className={cn("m-1 text-xs")}
                              title={
                                item?.label ? (
                                  <div className="flex items-center gap-1">
                                    <Icon
                                      className="mr-1"
                                      icon={field?.icon || ""}
                                    />
                                    {item?.label}
                                  </div>
                                ) : (
                                  "Select field"
                                )
                              }
                              onClick={() => {
                                if (
                                  item?.type === "select" ||
                                  item?.type === "multiselect"
                                ) {
                                  updateSetting(index, "ykeystate", {
                                    label: item?.datasource?.options[0]?.text,
                                    uid: item?.datasource?.options[0]?.uid,
                                  })
                                  if (!dataset?.name) {
                                    updateSetting(
                                      index,
                                      "name",
                                      item?.datasource?.options[0]?.text,
                                    )
                                  }
                                  updateSetting(
                                    index,
                                    "aggregationtype",
                                    "count",
                                  )
                                } else {
                                  if (!dataset?.name) {
                                    updateSetting(index, "name", item?.label)
                                  }
                                  updateSetting(index, "ykeystate", {
                                    label: "",
                                    uid: "",
                                  })
                                  updateSetting(index, "aggregationtype", "sum")
                                }

                                updateSetting(index, "ykey", {
                                  label: item?.label,
                                  uid: item?.key,
                                  type: item?.type,
                                  options: item?.datasource?.options.map(
                                    (o) => {
                                      return { uid: o.uid, text: o.text }
                                    },
                                  ),
                                })
                              }}
                            />
                          )
                        }}
                        button={CustomComponent({
                          value: _settings.datasets[index]?.ykey?.label || "",
                          label: "Field",
                          error:
                            errorChecking && !dataset.ykey.label
                              ? "required"
                              : undefined,
                          onCancel: () => {
                            updateSetting(index, "ykey", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "ykeystate", {
                              label: "",
                              uid: "",
                            })
                            updateSetting(index, "aggregationtype", "")
                          },
                        })}
                      />
                      <DropdownGeneric
                        collection={[...(dataset?.ykey?.options || [])]}
                        renderItem={({ item }) => (
                          <DropdownItem
                            className={cn("m-1 text-xs")}
                            title={item?.text || "Select field"}
                            onClick={() => {
                              if (
                                !dataset?.name ||
                                dataset?.name === dataset?.ykeystate?.label
                              ) {
                                updateSetting(index, "name", item.text)
                              }
                              updateSetting(index, "ykeystate", {
                                label: item?.text,
                                uid: item?.uid,
                              })
                            }}
                          />
                        )}
                        button={CustomComponent({
                          value: dataset?.ykeystate?.label || "",
                          label: "Field state",
                          error:
                            errorChecking &&
                            !!dataset?.ykey?.options &&
                            !dataset?.ykeystate?.label &&
                            "required",
                          disabled: !dataset?.ykey?.options
                            ? "You cannot edit the field state if no options are available"
                            : undefined,
                          onCancel: () =>
                            updateSetting(index, "ykeystate", {
                              label: "",
                              uid: "",
                            }),
                        })}
                      />
                      <DropdownGeneric
                        disabled={
                          dataset?.ykey?.type !== "money" &&
                          dataset?.ykey?.type !== "number"
                        }
                        collection={
                          dataset?.ykey?.type === "money"
                            ? ["sum", "min", "max", "avg"]
                            : ["count"]
                        }
                        renderItem={({ item }) => (
                          <DropdownItem
                            className={cn("m-1 text-xs")}
                            title={item || "Select field"}
                            onClick={() => {
                              if (!dataset?.name) {
                                updateSetting(index, "name", item)
                              }
                              updateSetting(index, "aggregationtype", item)
                            }}
                          />
                        )}
                        button={CustomComponent({
                          value: dataset?.aggregationtype || "",
                          label: "Aggregation type",
                          error:
                            errorChecking &&
                            ["money", "number"].includes(dataset?.ykey?.type) &&
                            !dataset?.aggregationtype &&
                            "required",
                          disabled:
                            dataset?.ykey?.type !== "money" &&
                            dataset?.ykey?.type !== "number"
                              ? "Aggregation type is only available for money and number fields"
                              : undefined,
                          onCancel: () =>
                            updateSetting(index, "aggregationtype", ""),
                        })}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </>
          <div
            onClick={() => {
              setSettings((prev) => {
                const defaultColors = randomHexColor()
                return {
                  ...prev,
                  datasets: [
                    ...(prev?.["datasets"] ?? []),
                    {
                      color: defaultColors,
                    },
                  ],
                }
              })
            }}
            className="flex h-fit cursor-pointer flex-col items-center space-y-3 rounded-md border-2 border-dashed p-5 text-gray-500 hover:bg-gray-100"
          >
            <div className="text-sm font-bold">+ Dataset</div>
          </div>
        </div>
      </div>
    </div>
  )
}
