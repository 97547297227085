import { faChartPie } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { ChartRender as Component } from "./ChartRender"
import { ChartSettings as Settings } from "./ChartSettings"

export default {
  name: "Pie chart",
  type: "PieChart",
  description: "Build a custom pie chart",
  color: "red",
  icon: faChartPie,
  categories: ["Analytics", "Featured"],
  Settings,
  Component,
  LayoutSize: {
    w: 12,
    h: 6,
  },
}
