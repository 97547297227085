import { companiesAtom } from "@/features/companies/components/atoms"
import { Icon } from "@/features/icons/components/IconLoader"
import { GenericDateRangePicker } from "@/shared/components/fields/GenericDateRangePicker"
import { faFilter } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import dayjs from "dayjs"
import { sumBy } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts"
import { useRecoilValueLoadable } from "recoil"
import { getAnalyticsWidgetData } from "../../api"
import { IAnalyticsWidgetDataResponse } from "../../types"

interface IChartRenderProps {
  dataset: string
  title: string
  datasourceuid: any
  datasets: any
}

export const ChartRender = ({
  dataset,
  title,
  datasourceuid,
  datasets,
}: IChartRenderProps) => {
  const [dates, setDates] = useState({
    from: new Date("2018-12-01"),
    to: new Date(),
  })
  const [datasetData, setDatasetData] = useState<
    IAnalyticsWidgetDataResponse[]
  >([])
  const [chartData, setChartData] = useState<any>([])
  const [chartColors, setChartColors] = useState<string[]>([])
  const [total, setTotal] = useState<any>()
  const [showFilters, setShowFilters] = useState(false)
  const { contents, state } = useRecoilValueLoadable(companiesAtom)
  const companies = state === "hasValue" ? contents : []
  const totalRef = useRef(0)

  useEffect(() => {
    getChartData()
  }, [datasourceuid, dates])

  const getChartData = useCallback(async () => {
    if (!datasourceuid) return
    await getAnalyticsWidgetData(
      {
        startDate: dayjs(dates.from).format("YYYY-MM"),
        endDate: dayjs(dates.to).format("YYYY-MM"),
      },

      datasourceuid,
    ).then((data) => {
      const sortedByName = data.sort((a, b) => b.name.localeCompare(a.name))
      if (sortedByName[0] === undefined) return
      handleSetChange(sortedByName)
      setDatasetData(sortedByName)
    })
  }, [datasourceuid, dates])

  const handleSetChange = (data) => {
    if (data === undefined) return
    const chartData = datasets.map((set) => ({
      name: set.name,
      value: sumBy(data, (d) => d[set.name.toLowerCase()] || 0),
    }))
    const total = chartData.reduce((acc, d) => acc + d.value, 0)
    datasets.map((d) => {
      setChartColors((prev) => [...prev, d.color])
    })
    setChartData(chartData)
    setTotal(total)
    totalRef.current = total
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    const [data] = payload
    const tooltipPayload = datasetData?.reduce((acc, dataset) => {
      const value = dataset?.[data?.name?.toLowerCase()]
      if (!!value) {
        const company = companies.find(
          (company) => company.uid === dataset.name,
        )
        acc.push({
          name: company?.name || dataset.name,
          value,
        })
      }
      return acc
    }, [])
    if (active && payload && payload.length) {
      return (
        <div className="h-fit space-y-2 rounded-md bg-white p-2 shadow-lg">
          <p className="text-lg font-medium capitalize">{data?.name}</p>
          {tooltipPayload
            .filter((p) => p.name !== "null")
            .sort((a, b) => b.value - a.value)
            .map((p) => (
              <div key={p.name} className="flex items-center gap-2">
                <p className="text-xs opacity-50">{p.name}</p>
                <p className="text-xs">: {p.value}</p>
              </div>
            ))}
        </div>
      )
    }

    return null
  }

  return (
    <>
      <div className="flex h-full flex-col overflow-hidden rounded-md bg-white p-2">
        <div className="flex justify-between">
          <p className="text-custom-gray-dark text-xl">{title || ""}</p>
          <div className="mr-2 flex items-center gap-2">
            {showFilters && (
              <GenericDateRangePicker
                className="field-input absolute"
                placeholder={[
                  dayjs(new Date(dates.from)).format("MMM YY"),
                  dayjs(new Date(dates.to)).format("MMM YY"),
                ]}
                format={"MMM YYYY"}
                value={[dayjs(new Date(dates.from)), dayjs(new Date(dates.to))]}
                onChange={(value) =>
                  setDates({
                    from: value?.[0].toDate() || new Date(),
                    to: value?.[1].toDate() || new Date(),
                  })
                }
                picker="month"
                dropdownClassName="h-8"
              />
            )}
            <Icon
              icon={faFilter}
              onClick={() => setShowFilters(!showFilters)}
              className="text-custom-gray-dark cursor-pointer"
            />
          </div>
        </div>

        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-semibold">
          {total}
        </div>
        <ResponsiveContainer width="100%" height="100%" className={"relative"}>
          <PieChart>
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              innerRadius={80}
              outerRadius={120}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={chartColors[index % chartColors.length]}
                  onMouseEnter={() => {
                    setTotal(entry.value)
                  }}
                  onMouseLeave={() => {
                    setTotal(totalRef.current)
                  }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
