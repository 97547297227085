import { atom, atomFamily, selector, selectorFamily } from "recoil"
import { getWidget, getWidgets } from "../api"

export const widgetsAtom = atom({
  key: "widgets",
  default: selector<any>({
    key: "widgets/default",
    get: async (): Promise<any[]> => {
      const widgets = await getWidgets()
      return widgets.map((widget) => ({
        ...widget,
      }))
    },
  }),
})

export const widgetAtom = atomFamily({
  key: "widget",
  default: selectorFamily({
    key: "widget/default",
    get:
      (uid: string) =>
      async ({ get }) => {
        return { ...((await getWidget(uid)) || {}) }
      },
  }),
})
