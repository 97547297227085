import { faBinary } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { Render as Component } from "./Render"
import { Settings } from "./Settings"

export default {
  name: "Number widget",
  type: "NumberWidget",
  description: "Abbility to display a calculated number",
  color: "purple",
  icon: faBinary,
  categories: ["Analytics", "Featured"],
  Settings,
  Component,
  LayoutSize: {
    w: 7,
    h: 2,
  },
}
