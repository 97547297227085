import {
  faLayerPlus,
  faMagnifyingGlass,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { TextInput } from "../../shared/components/fields"
import { HEX_OPACITY } from "../../utils/constants"
import WidgetTypes from "./components"
import { WidgetSettings } from "./components/WidgetSettings"
import { TWidgetResponse } from "./types"

const categories = [
  {
    name: "All",
  },
  {
    name: "Featured",
  },
  {
    name: "Analytics",
  },
  {
    name: "Marketing",
  },
  {
    name: "Elements",
  },
  {
    name: "Support",
  },
  {
    name: "Social",
  },
  {
    name: "Productivity",
  },
  {
    name: "Finance",
  },
]

export const Widgets = ({
  onSelectWidget,
}: {
  onSelectWidget?: (widget: TWidgetResponse) => void
}) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[1])
  const [search, setSearch] = useState(null)
  const [selectedWidget, setSelectedWidget] = useState(null)

  let widgets = Object.entries(WidgetTypes).reduce((acc, [key, value]) => {
    return [...acc, { type: key, ...value }]
  }, [])

  if (selectedCategory.name !== "All") {
    widgets = widgets.filter(
      (v) => !v.hidden && v.categories?.includes(selectedCategory.name),
    )
  }

  if (search) {
    widgets = widgets.filter((v) =>
      v.name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const handleSearch = (e) => {
    setSelectedCategory(categories[0])
    setSearch(e.target.value)
  }

  const renderTags = (tags: string[]) => {
    return tags?.map((tag) => {
      let color = "#4B5563"

      switch (tag) {
        case "New":
          color = "#e16b16"
          break
        case "Business":
          color = "#3B82F6"
          break
      }

      return (
        <div
          key={tag}
          style={{ color, backgroundColor: color + HEX_OPACITY["10"] }}
          className="rounded-md px-2 py-1 text-[10px] font-medium"
        >
          {tag}
        </div>
      )
    })
  }

  return (
    <div className="min-w-[664px] p-5">
      {selectedWidget !== null ? (
        <WidgetSettings
          uid={selectedWidget.uid}
          type={selectedWidget?.type}
          onSubmit={(widget) => onSelectWidget(widget)}
        />
      ) : (
        <>
          <div className="mb-7 flex items-center gap-3">
            <div className="text-xl font-medium">Widgets</div>
            <div className="flex flex-grow justify-center">
              <div className="relative min-w-[300px]">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  size="xs"
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500"
                />
                <TextInput
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search"
                  className="m-0 rounded-full border pl-8 text-xs focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="mr-10 flex w-1/5 flex-col">
              {categories.map((category) => {
                return (
                  <div
                    key={JSON.stringify(category)}
                    aria-selected={selectedCategory.name === category.name}
                    onClick={() => setSelectedCategory(category)}
                    className="flex cursor-pointer items-center p-2 hover:text-blue-500 aria-selected:rounded-md aria-selected:bg-blue-500 aria-selected:text-white"
                  >
                    <div className="text-sm font-medium">{category.name}</div>
                  </div>
                )
              })}
            </div>
            <div className="grid h-fit w-4/5 grid-cols-2 gap-3">
              {widgets.map((widget) => {
                const icon = widget?.icon ? widget?.icon : faLayerPlus
                const color = widget.color ? widget.color : "gray"
                return (
                  <div
                    key={widget.name}
                    onClick={() => setSelectedWidget(widget)}
                    className="shadow-xs relative flex w-full cursor-pointer flex-col items-center rounded-md border px-5 py-10 text-center text-gray-500 hover:bg-gray-100"
                  >
                    <div className="absolute left-2 top-2 flex gap-2">
                      {renderTags(widget.tags)}
                    </div>
                    <FontAwesomeIcon
                      className=""
                      style={{ color }}
                      size={"xl"}
                      icon={icon}
                    />
                    <div className="mb-1 mt-2 text-sm font-medium">
                      {widget.name}
                    </div>
                    <div className="text-xs text-gray-400">
                      {widget.description}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
