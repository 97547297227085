import { fetcher } from "@/utils/api"
import { ICandidateTemplateBody } from "../types"

export const getCandidateTemplates = async () => {
  return await fetcher("/candidate_templates")
}

export const getCandidateTemplate = async (uid: string) => {
  return await fetcher(`/candidate_templates/${uid}`)
}

export const getCandidateTemplateByTask = async (task: string) => {
  return await fetcher(`/candidate_templates/tasks/${task}`)
}

export const createCandidateTemplate = async (
  template: ICandidateTemplateBody,
) => {
  return await fetcher("/candidate_templates", {
    method: "POST",
    body: JSON.stringify({ template }),
  })
}

export const duplicateCandidateTemplate = async (uid: string) => {
  return await fetcher(`/candidate_templates/${uid}`, {
    method: "POST",
  })
}

export const updateCandidateTemplate = async (
  uid: string,
  template: ICandidateTemplateBody,
) => {
  return await fetcher(`/candidate_templates/${uid}`, {
    method: "PUT",
    body: JSON.stringify({ template }),
  })
}

export const deleteCandidateTemplate = async (uid: string) => {
  return await fetcher(`/candidate_templates/${uid}`, {
    method: "DELETE",
  })
}
