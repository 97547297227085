import { faTable } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { ChartRender as Component } from "./ChartRender"
import { ChartSettings as Settings } from "./ChartSettings"

export default {
  name: "Table",
  description: "Build a custom Table",
  color: "green",
  icon: faTable,
  categories: ["Analytics", "Featured"],
  Settings,
  Component,
  LayoutSize: {
    w: 30,
    h: 7,
  },
}
