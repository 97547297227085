import { Icon } from "@/features/icons/components/IconLoader"
import { cn } from "@/utils/helpers"
import { faXmark } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { Popover } from "antd"

export const CustomComponent = ({
  value,
  label,
  error,
  disabled,
  onCancel,
}: {
  value: string
  label: string
  error?: string | boolean
  disabled?: string
  onCancel?: () => void
}) => {
  return (
    <Popover content={disabled}>
      <div
        className={cn(
          "border-custom-gray relative flex h-10 cursor-pointer items-center gap-2 rounded-md border bg-white px-4 py-2",
          error && "border-red-500",
          disabled && "cursor-not-allowed bg-gray-100",
        )}
      >
        <span
          className={cn(
            "absolute transform bg-inherit px-0.5 text-sm leading-none transition-all",
            !disabled &&
              value &&
              "-translate-x-2.5 -translate-y-5 text-xs text-gray-400",
            error && "text-red-500",
            disabled && "bg-transparent text-gray-400",
          )}
        >
          {label}
        </span>
        <p className={cn("text-sm text-gray-600", disabled && "text-gray-300")}>
          {(!disabled && value) || " "}
        </p>
        {!disabled && value && onCancel && (
          <Icon
            icon={faXmark}
            size="sm"
            onClick={onCancel}
            className="z-50 ml-auto"
          />
        )}
      </div>
    </Popover>
  )
}
