import {
  faCircleCheck,
  faCog,
  faLayerPlus,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faLayerGroup } from "@awesome.me/kit-44b29310a6/icons/duotone/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FloatButton } from "antd"
import Error from "next/error"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { PortalHeader } from "../components/PortalHeader"
import Modal from "../components/modal/Modal"
import { useLayout } from "../features/customiser"
import { addLayoutToPage } from "../features/page/api"
import { PagesAtom } from "../features/page/atoms"
import { Widgets } from "../features/widgets"
import AllWidgets from "../features/widgets/components"
import { Widget } from "../features/widgets/components/Widget"
import { WidgetSettings } from "../features/widgets/components/WidgetSettings"

export const Page = () => {
  const { asPath } = useRouter()
  const pages = useRecoilValue(PagesAtom)

  const currentPage = pages.find((page) => page.path === asPath)

  const {
    render,
    addElement,
    setCallback,
    saveChanges,
    toggleEditMode,
    hasLayouts,
    isEditMode,
    hasLayoutChanges,
  } = useLayout(currentPage?.layouts?.[0])

  const [open, setOpen] = useState(false)
  const [clickedWidget, setClickedWidget] = useState(null)

  const [actionsOpen, setIsActionsOpen] = useState(false)

  const [, setRefresh] = useState(0)

  useEffect(() => {
    setCallback((action, data) => {
      if (action === "edit_clicked") {
        setClickedWidget(data)
        return
      }

      if (action === "layout_changed" && data?.uid) {
        addLayoutToPage(currentPage?.uid, data?.uid)
        return
      }

      setRefresh((prev) => prev + 1)
    })
  }, [])

  const addWidget = async (uid, { w = 20, h = 20 } = {}) => {
    addElement(uid, { w, h })
    setOpen(false)
    setClickedWidget(null)
  }

  if (!currentPage) return <Error statusCode={404} withDarkMode={false} />

  const isActionsOpen = isEditMode || actionsOpen

  const handleActionOpen = () => {
    setIsActionsOpen(!isActionsOpen)
    toggleEditMode()
  }

  return (
    <div className="relative h-full">
      <PortalHeader>
        <p className="text-xl text-white">{currentPage.name}</p>
      </PortalHeader>
      <Modal open={open} close={() => setOpen(false)}>
        <Widgets
          onSelectWidget={(widget) => {
            addWidget(widget.uid, AllWidgets[widget.type]?.LayoutSize)
          }}
        />
      </Modal>

      {clickedWidget !== null && (
        <Modal
          open={clickedWidget !== null}
          close={() => setClickedWidget(null)}
        >
          <WidgetSettings
            uid={clickedWidget}
            type=""
            onSubmit={() => setClickedWidget(null)}
          />
        </Modal>
      )}
      <>
        <FloatButton.Group
          open={isActionsOpen}
          trigger="click"
          style={{ right: 30, bottom: 30 }}
          onClick={() => handleActionOpen()}
          icon={<FontAwesomeIcon icon={faCog} />}
        >
          {hasLayoutChanges && (
            <FloatButton
              className="hover:bg-white"
              onClick={() => {
                saveChanges()
                handleActionOpen()
              }}
              icon={
                <FontAwesomeIcon
                  className="text-green-400"
                  icon={faCircleCheck}
                />
              }
            />
          )}
          <FloatButton
            className="hover:bg-white"
            tooltip={<>Add widget</>}
            onClick={() => setOpen(true)}
            icon={<FontAwesomeIcon icon={faLayerPlus} />}
          />
        </FloatButton.Group>
      </>
      <div className="relative">
        {hasLayouts ? (
          render({
            renderItem: ({ item }) => {
              return <Widget key={item.i} uid={item.i} />
            },
          })
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center">
            <div className="border-custom-gray-dark rounded-full border-2 p-3 text-4xl font-bold text-gray-500">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div className="text-sm text-gray-500">
              This is a widget-based page!
              <br />
              Start designing your page as desired by using widgets
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Page
