import { cn } from "@/utils/helpers"
import { faXmark } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useMemo, useState } from "react"
import { useRecoilStateLoadable } from "recoil"
import { toast } from "sonner"
import { BasicButton } from "../../../components/buttons/BasicButton"
import { addWidgets, createDataset, putWidget } from "../api"
import { widgetAtom, widgetsAtom } from "../atoms"
import Widgets from "./index"

export const WidgetSettings = ({
  uid,
  type,
  onSubmit = (widget) => void 0,
}) => {
  const [localWidget, setLocalWidget] = useState({ uid: null })
  const [_type, setType] = useState(type)
  const [postLoading, setPostLoading] = useState(false)
  const [errorChecking, setErrorChecking] = useState(false)

  const [{ contents: widget, state: isLoading }, setWidget] =
    useRecoilStateLoadable(widgetAtom(uid ?? localWidget.uid))

  const [settings, setSettings] = useState<any>({})

  const Settings = useMemo(() => Widgets[_type]?.Settings, [_type])

  useEffect(() => {
    if (type) setType(type)
  }, [type])

  useEffect(() => {
    if (isLoading === "hasValue") {
      if (localWidget.uid) {
        setWidget((prev) => ({ type: _type, ...prev, ...localWidget }))
      }

      if (widget.settings) setSettings(widget.settings)
      if (widget.type) setType(widget.type)
    }
  }, [isLoading])

  const [, setWidgets] = useRecoilStateLoadable(widgetsAtom)

  if (isLoading !== "hasValue") return null

  const handleSubmit = async () => {
    toast.promise(handleSaveWidget(), {
      loading: "Saving widget...",
      success: () => {
        onSubmit(widget)
        return "Widget successfully saved!"
      },
      error: "Failed to save widget!",
      finally: () => setPostLoading(false),
    })
  }

  const handleSaveWidget = async () => {
    try {
      setPostLoading(true)
      const newSettingsR = await handleCreateDataset(settings)
      const newSettings = {
        ...settings,
        datasourceuid: newSettingsR,
        datasets: settings.datasets.map((set) => ({
          ...set,
          ...(set?.template?.uid
            ? { template: { uid: set.template.uid, label: set.template.label } }
            : {}),
        })),
      }

      let w = { type: _type, ...widget, settings: newSettings }

      if (w?.uid) {
        w = await putWidget(w.uid, w.settings)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setWidget({ ...w, sources: widget.sources || {} })
      } else {
        w = await addWidgets(w)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLocalWidget({ ...w, sources: widget.sources || {} })
      }

      await setWidgets((prev) => [...prev, { ...w }])
      setPostLoading(false)
      onSubmit(w)
    } catch (e) {
      setErrorChecking(true)
      toast.error("Failed to save widget...")
      setPostLoading(false)
    }
  }

  const handleCreateDataset = async (chartConfig: any) => {
    const xKey = chartConfig.datasets?.[0]?.xkey?.uid
    const datasetPostBody = {
      sourceType: chartConfig.sourcetype,
      xKey: xKey ? xKey : null,
      sets: chartConfig.datasets.map((set) => ({
        name: set.name,
        key: set?.ykey?.uid,
        value: set?.ykeystate?.uid || null,
        aggregate_type: set.aggregationtype,
        filters: set?.template?.uid ? [["_template", set?.template?.uid]] : [],
      })),
    }
    return await createDataset(datasetPostBody).then((res) => res.uid)
  }

  return (
    <>
      <div className="mb-4 flex items-center">
        <p className="text-custom-header-dark text-center text-xl font-semibold">
          Configure your widget
        </p>
        <p className="ml-auto cursor-pointer" onClick={onSubmit}>
          <FontAwesomeIcon icon={faXmark} size="xl" />
        </p>
      </div>
      <div className="border-custom-gray group relative flex items-center rounded-md border bg-white px-4 py-2 ring-inset group-focus:ring-2">
        <input
          value={settings?.title ?? ""}
          onChange={(e) =>
            setSettings((prev) => ({ ...prev, title: e.target.value }))
          }
          className="w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white"
        />
        <span
          className={cn(
            "pointer-events-none absolute ml-0.5 transform bg-inherit text-sm leading-none transition-all",
            settings?.title &&
              "mx-0 -translate-x-2.5 -translate-y-5 px-0.5 text-xs text-gray-400",
          )}
        >
          Widget title
        </span>
      </div>
      {Settings && (
        <Settings
          settings={settings}
          errorChecking={errorChecking}
          onChange={(settings) => {
            setSettings((prev) => ({ ...prev, ...settings }))
          }}
        />
      )}
      <div className="flex justify-end gap-2">
        <BasicButton variant="orange" onClick={onSubmit}>
          Close
        </BasicButton>
        <BasicButton
          variant="green"
          onClick={handleSubmit}
          loading={postLoading}
        >
          Save changes
        </BasicButton>
      </div>
    </>
  )
}
