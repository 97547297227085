import { Icon } from "@/features/icons/components/IconLoader"
import { GenericDateRangePicker } from "@/shared/components/fields/GenericDateRangePicker"
import { faFilter } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { getAnalyticsWidgetData } from "../../api"
import { IAnalyticsWidgetDataResponse } from "../../types"

interface IChartRenderProps {
  dataset: string
  title: string
  datasourceuid: any
  datasets: any
}

export const ChartRender = ({
  title,
  datasourceuid,
  datasets,
}: IChartRenderProps) => {
  const [chartData, setChartData] = useState<IAnalyticsWidgetDataResponse[]>([])
  const [dates, setDates] = useState({
    from: new Date("2021-12-01"),
    to: new Date(),
  })
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    getChartData()
  }, [datasourceuid, dates])

  const getChartData = useCallback(
    async () =>
      datasourceuid &&
      (await getAnalyticsWidgetData(
        {
          startDate: dayjs(dates.from).format("YYYY-MM"),
          endDate: dayjs(dates.to).format("YYYY-MM"),
        },
        datasourceuid,
      ).then((data) => {
        const sortedByName = data.sort((a, b) => a.name.localeCompare(b.name))
        setChartData(sortedByName)
      })),
    [datasourceuid, dates],
  )

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="h-fit space-y-2 rounded-md bg-white p-2 shadow-lg">
          <p className="text-lg font-medium capitalize">{label}</p>
          {payload
            .filter((p) => p.name !== "null")
            .sort((a, b) => b.value - a.value)
            .map((p) => (
              <div key={p.name} className="flex items-center gap-2">
                <p className="text-xs opacity-50">{p.name}</p>
                <p className="text-xs">: {p.value}</p>
              </div>
            ))}
        </div>
      )
    }

    return null
  }

  return (
    <div className="flex h-full flex-col overflow-hidden rounded-md bg-white p-2">
      {title?.length > 0 && (
        <div className="flex justify-between">
          <p className="text-custom-gray-dark text-xl">{title}</p>
          <div className="mr-2 flex items-center gap-2">
            {showFilters && (
              <GenericDateRangePicker
                className="field-input"
                placeholder={[
                  dayjs(new Date(dates.from)).format("MMM YY"),
                  dayjs(new Date(dates.to)).format("MMM YY"),
                ]}
                value={[dayjs(new Date(dates.from)), dayjs(new Date(dates.to))]}
                onChange={(value) =>
                  setDates({
                    from: value?.[0].toDate() || new Date(),
                    to: value?.[1].toDate() || new Date(),
                  })
                }
                picker="month"
                dropdownClassName="h-8"
              />
            )}
            <Icon
              icon={faFilter}
              onClick={() => setShowFilters(!showFilters)}
              className="text-custom-gray-dark cursor-pointer"
            />
          </div>
        </div>
      )}
      <div className="h-full">
        <ResponsiveContainer
          height={"99%"}
          onResize={function noRefCheck() {}}
          width="100%"
        >
          <ComposedChart data={chartData}>
            <XAxis
              dataKey="name"
              interval={0}
              height={40}
              tickMargin={10}
              tickFormatter={(value) => {
                return dayjs(new Date(value)).format("MMM YY")
              }}
              padding={{ left: 10, right: 10 }}
              stroke="#79879B"
            />
            <YAxis stroke="#79879B" width={40} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <defs>
              {chartData.length &&
                Object.keys(chartData[0]).map((key, index) => {
                  if (key === "name") return null
                  const set = datasets.find((d) => d.name.toLowerCase() === key)
                  if (!set || set?.charttype?.type !== "AreaChart") return null
                  return (
                    <linearGradient
                      key={index}
                      id={`color_${set.color}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={set.color}
                        stopOpacity={0.6}
                      />
                      <stop
                        offset="80%"
                        stopColor={set.color}
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  )
                })}
            </defs>
            {chartData.length &&
              Object.keys(chartData[0]).map((key, index) => {
                if (key === "name") return null
                const set = datasets.find((d) => d.name.toLowerCase() === key)
                if (!set) return null
                switch (set.charttype?.type) {
                  case "LineChart":
                    return (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={key}
                        stroke={set.color}
                        fill={set.color}
                        fillOpacity={0.4}
                      />
                    )
                  case "BarChart":
                    return (
                      <Bar
                        key={index}
                        type="monotone"
                        dataKey={key}
                        stroke={set.color}
                        strokeWidth={1}
                        fillOpacity={0.2}
                        fill={set.color}
                        radius={[5, 5, 0, 0]}
                      />
                    )
                  case "ScatterChart":
                    return (
                      <Scatter
                        key={index}
                        type="monotone"
                        dataKey={key}
                        stroke={set.color}
                        fillOpacity={0.4}
                        fill={set.color}
                      />
                    )
                  default:
                    return (
                      <Area
                        type="monotone"
                        dataKey={key}
                        stroke={set.color}
                        fillOpacity={1}
                        fill={`url(#color_${set.color})`}
                      />
                    )
                }
              })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
