import { fetcher } from "../../../utils/api"
import { ILayoutType } from "../customiserTypes"

export const addLayout = async ({ columns, row_height }) => {
  return await fetcher(`/layouts`, {
    method: "POST",
    body: JSON.stringify({
      columns,
      row_height,
    }),
  })
}

export const getLayout = async (uid): Promise<ILayoutType> => {
  return await fetcher(`/layouts/${uid}`)
}

export const addElementToLayout = async (
  uid,
  { widget, x, y, h, w, is_static },
) => {
  return await fetcher(`/layouts/${uid}/elements`, {
    method: "POST",
    body: JSON.stringify({
      widget,
      x,
      y,
      h,
      w,
      static: is_static,
    }),
  })
}

export const updateLayoutElement = async (
  layout,
  uid,
  { widget, x, y, h, w, is_static },
) => {
  return await fetcher(`/layouts/${layout}/elements/${uid}`, {
    method: "PUT",
    body: JSON.stringify({
      widget,
      x,
      y,
      h,
      w,
      static: is_static,
    }),
  })
}

export const deleteLayoutElement = async (layout, uid) => {
  return await fetcher(`/layouts/${layout}/elements/${uid}`, {
    method: "DELETE",
  })
}
