import { companiesAtom } from "@/features/companies/components/atoms"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { isValidDate } from "@/utils/helpers"
import { Popover, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useRecoilValueLoadable } from "recoil"
import { getAnalyticsWidgetData } from "../../api"
import { IAnalyticsWidgetDataResponse } from "../../types"

interface IChartRenderProps {
  dataset: string
  title: string
  datasourceuid: any
  datasets: any
}

export const ChartRender = ({
  title,
  datasourceuid,
  datasets,
}: IChartRenderProps) => {
  const [chartData, setChartData] = useState<IAnalyticsWidgetDataResponse[]>([])
  const [dates, setDates] = useState({
    from: new Date("2021-12-01"),
    to: new Date(),
  })
  const xkey = datasets?.[0]?.xkey?.uid
  const isCustomerOrSupplier = xkey === "_customer" || xkey === "_supplier"
  const { contents, state } = useRecoilValueLoadable(companiesAtom)
  const companies = state === "hasValue" ? contents : []

  useEffect(() => {
    getChartData()
  }, [datasourceuid, dates, isCustomerOrSupplier, companies])

  const getChartData = async () => {
    if (!datasourceuid) return
    await getAnalyticsWidgetData(
      {
        startDate: dayjs(dates.from).format("YYYY-MM"),
        endDate: dayjs(dates.to).format("YYYY-MM"),
      },
      datasourceuid,
    ).then((data) => {
      const sortedByName = data.sort((a, b) => a.name.localeCompare(b.name))
      if (isCustomerOrSupplier) {
        const filteredData = sortedByName.filter((set) =>
          companies.some((company) => company.uid === set.name),
        )
        setChartData(filteredData)
      } else {
        setChartData(sortedByName)
      }
    })
  }

  const columns: ColumnsType<any> = datasets.map((set) => ({
    key: set.name.toLowerCase(),
    dataIndex: set.name.toLowerCase(),
    title: set.name,
    sorter: (a: any, b: any) =>
      a[set.name.toLowerCase()] - b[set.name.toLowerCase()],
  }))

  const groupColumn: ColumnsType<any> = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      filters: chartData.map((set) => {
        if (isCustomerOrSupplier) {
          const company = companies.find((company) => company.uid === set.name)
          return { text: company?.name, value: company?.name }
        }
        return { text: set.name, value: set.name }
      }),
      onFilter: (value, record) => {
        if (isCustomerOrSupplier) {
          const company = companies.find(
            (company) => company.uid === record.name,
          )
          return company?.name === value
        }
        return record.name.indexOf(value) === 0
      },
      filterSearch: true,
      render: (name: string) => {
        const isDate = isValidDate(name)
        if (isDate) {
          return dayjs(name).format("MMM YYYY")
        }
        if (isCustomerOrSupplier) {
          const company = companies.find((company) => company.uid === name)
          if (!company) {
            return "Unknown"
          }
          return (
            <div className="flex items-center">
              <div className="w-[110px] min-w-[110px] border-r border-gray-300">
                <ImageWithFallback
                  src={ORGANISATION_AVATERS(company.uid)}
                  alt={company.name}
                  width={96}
                  height={48}
                />
              </div>
              <Popover content={company.name}>
                <span className="truncate pl-4 text-xs opacity-50">
                  {company.name}
                </span>
              </Popover>
            </div>
          )
        }
        return name
      },
    },
  ]

  const fooRef = useRef(null)
  return (
    <div className="h-full space-y-2 p-2" ref={fooRef}>
      <p className="text-custom-gray-dark text-xl">{title}</p>
      <Table
        virtual
        scroll={{ y: fooRef.current?.clientHeight - 100 }}
        dataSource={chartData}
        columns={[...groupColumn, ...columns]}
        pagination={false}
        sticky
      />
    </div>
  )
}
