import { faChartLine } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { ChartRender as Component } from "./ChartRender"
import { ChartSettings as Settings } from "./ChartSettings"

export default {
  name: "Historical Chart",
  type: "LineChart",
  description:
    'Build a custom "data over time" graph. Visualize with lines, bars or scatter plotting.',
  color: "red",
  icon: faChartLine,
  categories: ["Analytics", "Featured"],
  Settings,
  Component,
  LayoutSize: {
    w: 20,
    h: 7,
  },
}
