import { cn } from "@/utils/helpers"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { getAnalyticsWidgetData } from "../../api"

export const Render = ({ title, datasourceuid, datasets }: any) => {
  const [dates] = useState({
    from: new Date("2021-12-01"),
    to: new Date("2024-12-31"),
  })
  const [chartData, setChartData] = useState({ label: "", color: "", value: 0 })

  const getChartData = useCallback(
    async () =>
      datasourceuid &&
      (await getAnalyticsWidgetData(
        {
          startDate: dayjs(dates.from).format("YYYY-MM"),
          endDate: dayjs(dates.to).format("YYYY-MM"),
        },
        datasourceuid,
      ).then((data) => {
        const sortedByName = data.sort((a, b) => b.name.localeCompare(a.name))
        const foo = sortedByName[0] // !this takes the first dataset in the array of latests.
        Object.keys(foo).forEach((key) => {
          if (key === "name" || key === "total") return
          const set = datasets.find((d) => d.name.toLowerCase() === key)
          if (!set) {
            return
          }
          setChartData({
            label: set.name,
            color: set.color,
            value: Number(foo[set.name.toLowerCase()]),
          })
        })
      })),
    [],
  )

  useEffect(() => {
    getChartData()
  }, [datasourceuid])

  return (
    <div className="flex h-full flex-col justify-center overflow-hidden rounded-md bg-white">
      <div className="flex h-8 items-center justify-center text-center">
        <p className="text-custom-gray-dark text-xl">
          {title || chartData.label}
        </p>
      </div>

      <div className="relative mx-auto h-12 w-full">
        <div className="transfrom absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div
            className={cn("text-4xl font-bold")}
            style={{ color: chartData.color }}
          >
            {chartData.value}
          </div>
        </div>
      </div>
    </div>
  )
}
