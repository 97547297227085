import JavascriptSnippet from "./JavascriptSnippet"
import LineChart from "./LineChart"
import NumberWidget from "./NumberWidget"
import PieChart from "./PieChart"
import Table from "./Table"

export default {
  JavascriptSnippet,
  LineChart,
  NumberWidget,
  PieChart,
  Table,
}
