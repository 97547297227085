import { faSquareJs } from "@awesome.me/kit-44b29310a6/icons/classic/brands"
import { JavascriptSnippetComponent as Component } from "./Component"
import { JavascriptSnippetSettings as Settings } from "./Settings"

export default {
  name: "Javascript Snippet",
  description: "Add a custom javascript snippet",
  color: "#f7df1e",
  icon: faSquareJs,
  categories: ["Elements"],
  Settings,
  Component,
  LayoutSize: {
    w: 20,
    h: 10,
  },
}
