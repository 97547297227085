import { getContractTemplates } from "@/features/contract_templates/utils/api"
import { fieldsRegistry } from "@/features/fields/registry"
import { IField } from "@/features/fields/types"
import { Icon } from "@/features/icons/components/IconLoader"
import { getTaskTemplate, getTaskTemplates } from "@/features/templates/api"
import { ITemplate } from "@/features/templates/types"
import { DropdownGeneric } from "@/shared/components/fields/DropdownGeneric"
import { ColorPickerMinimalInput } from "@/shared/components/inputs/ColorPickerMininal"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { cn } from "@/utils/helpers"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { TSourceIndex } from "../../types"
import { CustomComponent } from "../InputComponent"

import { atom, useRecoilState } from "recoil"
import { DEFAULT_FIELDS, SOURCE_TYPES } from "../../utils/constants"

const templateUidAtom = atom({
  key: "widgets",
  default: "",
})

export const Settings = ({
  onChange,
  widget,
  settings,
  errorChecking,
}: {
  onChange: any
  widget: any
  settings: any
  errorChecking: any
}) => {
  const [_settings, setSettings] = useState({
    ...settings,
    datasets: settings?.datasets?.[0],
  })
  const [datasourceTemplates, setDatasourceTemplates] = useState<ITemplate[]>()
  const [availableFields, setAvailableFields] = useState<IField[]>([])
  const [templateUid, setTemplateUid] = useRecoilState(templateUidAtom)
  const handleChanges = (key: string) => (value: any) => {
    setSettings((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const updateAvailableFields = useCallback(async () => {
    if (!_settings?.datasets) return
    const _fetchedTaskTemplateFields = (await getTaskTemplate(templateUid))
      .fields
    setAvailableFields(
      _fetchedTaskTemplateFields?.filter(
        (field) =>
          field.type === "number" ||
          field.type === "money" ||
          field.type === "select",
      ),
    )
  }, [templateUid])

  useEffect(() => {
    const uid = _settings?.datasets?.template?.uid || ""
    if (!uid) return
    if (templateUid !== uid) setTemplateUid(uid)
  }, [_settings])

  useEffect(() => {
    updateAvailableFields()
  }, [templateUid])

  useEffect(() => {
    onChange?.({ ..._settings, datasets: [_settings.datasets] })
  }, [_settings])

  const handleTemplateChange = async (type: TSourceIndex) => {
    switch (type) {
      case "tasks":
      case "rfqs":
        setSettings({
          ..._settings,
          sourcetype: type as any,
        })
        await getTaskTemplates().then((data) => {
          if (type === "rfqs") {
            const minibidTemplates = data.filter(
              (template) => template.is_minibid,
            )
            setDatasourceTemplates(minibidTemplates)
          } else {
            const taskTemplates = data.filter(
              (template) => !template.is_minibid,
            )
            setDatasourceTemplates(taskTemplates)
          }
        })
        break
      case "contracts":
        setSettings({
          ..._settings,
          sourcetype: "contracts",
        })
        await getContractTemplates().then((data) => {
          setDatasourceTemplates(data)
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    handleTemplateChange(_settings?.sourcetype)
  }, [_settings?.sourcetype])

  const updateSetting = (key, value) => {
    if (!_settings) return
    setSettings((prevSettings) => {
      return {
        ...prevSettings,
        datasets: {
          ...prevSettings.datasets,
          [key]: value,
        },
      }
    })
  }

  return (
    <div className="my-3 flex flex-col gap-2">
      <div className="text-md my-2 font-medium">Datasets</div>
      <DropdownGeneric
        collection={SOURCE_TYPES}
        renderItem={({ item }) => (
          <DropdownItem
            className={cn("m-1 text-xs")}
            title={item.name}
            onClick={() => {
              handleChanges("datasource")(item.value)
              handleTemplateChange(item.value)
            }}
            icon={item.icon}
            selected
          />
        )}
        disabled={false}
        button={CustomComponent({
          value: _settings?.sourcetype || "",
          label: "Source",
          error:
            !_settings?.sourcetype &&
            _settings?.datasets &&
            _settings?.datasets.length > 0
              ? "Source is required"
              : undefined,
        })}
      />

      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col items-center space-y-3 rounded-md border p-3 text-gray-500">
          <div className="flex items-center gap-1">
            <div className="cursor-default text-sm font-bold">
              {_settings?.dataset?.name || "Dataset"}
            </div>
          </div>
          <div className="flex w-full flex-col gap-3">
            <div className="border-custom-gray group relative flex items-center rounded-md border bg-white px-4 py-2 ring-inset group-focus:ring-2">
              <input
                value={_settings?.datasets?.name || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  updateSetting("name", e.target.value)
                }
                className={cn(
                  "w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
                  errorChecking &&
                    _settings?.datasets &&
                    !_settings?.datasets[0]?.name &&
                    "border-red-500",
                )}
              />
              <span
                className={cn(
                  "pointer-events-none absolute ml-0.5 transform bg-inherit text-sm leading-none transition-all",
                  _settings.datasets?.name &&
                    "mx-0 -translate-x-2.5 -translate-y-5 px-0.5 text-xs text-gray-400",
                )}
              >
                Dataset name
              </span>
              <ColorPickerMinimalInput
                color={_settings.datasets?.color}
                onSelect={(color) => updateSetting("color", color)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <DropdownGeneric
                collection={datasourceTemplates || []}
                renderItem={({ item }) => (
                  <DropdownItem
                    className={cn("m-1 text-xs")}
                    title={item.name}
                    onClick={() => {
                      updateSetting("template", {
                        label: item.name,
                        uid: item.uid,
                      })
                      updateSetting("ykey", {
                        label: "",
                        uid: "",
                      })
                      updateSetting("ykeystate", { label: "", uid: "" })
                      updateSetting("aggregationtype", "")
                    }}
                    selected
                  />
                )}
                button={CustomComponent({
                  value: _settings.datasets?.template?.label || "",
                  label: "Template",
                  error:
                    errorChecking && !_settings?.datasets?.template.label
                      ? "required"
                      : undefined,
                  onCancel: () => {
                    updateSetting("template", {
                      label: "",
                      uid: "",
                    })
                    updateSetting("ykey", {
                      label: "",
                      uid: "",
                    })
                    updateSetting("ykeystate", { label: "", uid: "" })
                    updateSetting("aggregationtype", "")
                  },
                })}
              />

              <DropdownGeneric
                collection={
                  _settings.datasets?.template?.uid
                    ? ([
                        ...(DEFAULT_FIELDS[settings?.sourcetype] || []),
                        ...(availableFields || []),
                      ] as any[]) //|| []
                    : []
                }
                renderItem={({ item }) => {
                  const field = fieldsRegistry.find((f) => f.key === item?.type)
                  return (
                    <DropdownItem
                      className={cn("m-1 text-xs")}
                      title={
                        item?.label ? (
                          <div className="flex items-center gap-1">
                            <Icon className="mr-1" icon={field?.icon || ""} />
                            {item?.label}
                          </div>
                        ) : (
                          "Select field"
                        )
                      }
                      onClick={() => {
                        if (
                          item?.type === "select" ||
                          item?.type === "multiselect"
                        ) {
                          updateSetting("ykeystate", {
                            label: item?.datasource?.options[0]?.text,
                            uid: item?.datasource?.options[0]?.uid,
                          })

                          if (
                            !_settings.datasets?.name ||
                            _settings.datasets?.name ===
                              _settings.datasets?.ykeystate?.label
                          ) {
                            updateSetting(
                              "name",
                              item?.datasource?.options[0]?.text,
                            )
                          }
                          updateSetting("aggregationtype", "count")
                        } else {
                          if (!_settings.datasets?.name) {
                            updateSetting("name", item?.label)
                          }
                          updateSetting("ykeystate", { label: "", uid: "" })
                          updateSetting("aggregationtype", "sum")
                        }
                        updateSetting("ykey", {
                          label: item?.label,
                          uid: item?.key,
                          type: item?.type,
                          options: item?.datasource?.options.map((o) => {
                            return { uid: o.uid, text: o.text }
                          }),
                        })
                      }}
                      selected
                    />
                  )
                }}
                button={CustomComponent({
                  value: _settings?.datasets?.ykey?.label || "",
                  label: "Field",
                  error:
                    errorChecking && !_settings?.datasets?.ykey.label
                      ? "required"
                      : undefined,
                  onCancel: () => {
                    updateSetting("ykey", {
                      label: "",
                      uid: "",
                    })
                    updateSetting("ykeystate", { label: "", uid: "" })
                    updateSetting("aggregationtype", "")
                  },
                })}
              />
              <DropdownGeneric
                disabled={!_settings.datasets?.ykey?.options}
                collection={_settings.datasets?.ykey?.options || []}
                renderItem={({
                  item,
                }: {
                  item: { text: string; uid: string }
                }) => (
                  <DropdownItem
                    className={cn("m-1 text-xs")}
                    title={item?.text || "Select field"}
                    onClick={() => {
                      if (
                        !_settings.datasets?.name ||
                        _settings.datasets?.name ===
                          _settings.datasets?.ykeystate?.label
                      ) {
                        updateSetting("name", item.text)
                      }
                      updateSetting("ykeystate", {
                        label: item?.text,
                        uid: item?.uid,
                      })
                    }}
                  />
                )}
                button={CustomComponent({
                  value: _settings.datasets?.ykeystate?.label || "",
                  label: "Field state",
                  disabled: !_settings.datasets?.ykey?.options
                    ? "You cannot edit the field state if no options are available"
                    : undefined,
                  onCancel: () => {
                    updateSetting("ykeystate", { label: "", uid: "" })
                    updateSetting("aggregationtype", "")
                  },
                })}
              />

              <DropdownGeneric
                disabled={
                  _settings.datasets?.ykey?.type !== "money" &&
                  _settings.datasets?.ykey?.type !== "number"
                }
                collection={
                  _settings.datasets?.ykey?.type === "money"
                    ? ["sum", "min", "max", "avg"]
                    : ["count"]
                }
                renderItem={({ item }) => (
                  <DropdownItem
                    className={cn("m-1 text-xs")}
                    title={item || "Select field"}
                    onClick={() => updateSetting("aggregationtype", item)}
                  />
                )}
                button={CustomComponent({
                  value: _settings?.datasets?.aggregationtype || "",
                  label: "Aggregation type",
                  error:
                    errorChecking &&
                    _settings.datasets?.ykey?.type &&
                    _settings.datasets?.ykey?.type !== "money" &&
                    _settings.datasets?.ykey?.type !== "number" &&
                    !_settings?.datasets?.aggregationtype &&
                    "required",
                  disabled:
                    _settings.datasets?.ykey?.type !== "money" &&
                    _settings.datasets?.ykey?.type !== "number"
                      ? "Aggregation type is only available for money and number fields"
                      : undefined,
                  onCancel: () => {
                    updateSetting("aggregationtype", "")
                  },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
