import { useRecoilValueLoadable } from "recoil"
import { widgetAtom } from "../atoms"
import WidgetsTypes from "./index"

export const Widget = ({ uid }: { uid: string }) => {
  const { contents: widget, state: isLoading } = useRecoilValueLoadable(
    widgetAtom(uid),
  )

  if (isLoading === "loading") return <>...loading</>
  if (isLoading === "hasError" || !widget) return <>Widget doesn&apos;t exist</>

  const Component = WidgetsTypes[widget.type]?.Component
  if (!Component) return "Widget type not found"

  return <Component {...widget.settings} />
}
