import { fetcher } from "@/utils/api"
import qs from "qs"
import {
  IAnalyticsWidgetDataResponse,
  IDataset,
  TDatasetPostBody,
  TDatasetPostResponse,
  TWidgetPostBody,
  TWidgetResponse,
} from "../types"

export const getWidgets = async (): Promise<TWidgetResponse[]> => {
  return await fetcher(`/widgets`)
}

export const getWidget = async (uid: string): Promise<TWidgetResponse> => {
  return await fetcher(`/widgets/${uid}`)
}

export const putWidget = async (
  uid: string,
  settings: Pick<TWidgetResponse, "settings">,
) => {
  return await fetcher(`/widgets/${uid}`, {
    method: "PUT",
    body: JSON.stringify(settings),
  })
}

export const addWidgets = async <TChartDataset extends object>({
  settings,
  type,
}: TWidgetPostBody<TChartDataset>): Promise<TWidgetResponse> => {
  return await fetcher(`/widgets`, {
    method: "POST",
    body: JSON.stringify({
      settings,
      type,
    }),
  })
}

export const getAnalyticsWidgetData = async (
  params: { startDate: string; endDate: string },
  datasetUid: string,
): Promise<IAnalyticsWidgetDataResponse[]> => {
  const queryString = qs.stringify(params)
  return await fetcher(`/analytics/${datasetUid}/data?${queryString}`)
}

export const getDatasets = async (): Promise<IDataset[]> => {
  return await fetcher(`/analytics`)
}

export const createDataset = async (
  datasets: TDatasetPostBody,
): Promise<TDatasetPostResponse> => {
  return await fetcher(`/analytics`, {
    method: "POST",
    body: JSON.stringify(datasets),
  })
}
