import {
  faGears,
  faGripDots,
  faTrash,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cloneDeep } from "lodash"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { Layouts, Responsive, WidthProvider } from "react-grid-layout"
import {
  addElementToLayout,
  addLayout,
  deleteLayoutElement,
  getLayout,
  updateLayoutElement,
} from "./api"
import { ILayoutType, IWidgetElement } from "./customiserTypes"

const GridItem = forwardRef<any, any>(
  (
    {
      uid,
      children,
      editable,
      onEdit = () => void 0,
      onDelete = () => void 0,
      className,
      ...restOfProps
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        {...restOfProps}
        data-key={uid}
        className={className + " overflow-hidden rounded-md bg-white"}
      >
        {editable && (
          <div className="absolute right-2 top-2 z-50 flex items-center gap-3 rounded-md border border-gray-600 bg-white px-2 py-1">
            <div
              onClick={() => onEdit(ref)}
              className="cursor-pointer border-r pr-2"
            >
              <FontAwesomeIcon icon={faGears} size="lg" />
            </div>
            <div
              onClick={() => onDelete(ref)}
              className="cursor-pointer border-r pr-2"
            >
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </div>
            <div className="drag-handle cursor-grab">
              <FontAwesomeIcon icon={faGripDots} size="lg" />
            </div>
          </div>
        )}
        {children}
      </div>
    )
  },
)

GridItem.displayName = "GridItem"

const layoutsPatterns = { lg: [], sm: [], xs: [], xxs: [], md: [] }

const GridLayout = ({
  edit_mode = false,
  sizes = { columns: 50, row_height: 50 },
  layouts,
  setLayouts,
  renderItem,
  onDeleteItem,
  onEditClicked,
}: {
  edit_mode?: boolean
  layouts?: Layouts
  sizes?: {
    columns: number
    row_height: number
  }
  setLayouts: (s: any) => void
  refresh?: number
  renderItem?: (item: Record<string, any>) => JSX.Element
  onDeleteItem?: (string) => void
  onEditClicked?: (ref) => void
}) => {
  const [editMode, setEditMode] = useState(edit_mode)
  const [breakpoint, setBreakpoint] = useState("lg" as string)

  useEffect(() => {
    setEditMode(edit_mode)
  }, [edit_mode])

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), [])

  const { columns, row_height } = sizes

  const GridLayout = useMemo(() => {
    return (
      <ResponsiveReactGridLayout
        autoSize={true}
        draggableHandle=".drag-handle"
        isDraggable={editMode}
        isResizable={editMode}
        resizeHandles={["se"]}
        compactType={"vertical"}
        verticalCompact={true}
        layouts={layouts ?? layoutsPatterns}
        cols={{
          lg: columns,
          md: columns,
          sm: columns,
          xs: columns,
          xxs: columns,
        }}
        rowHeight={row_height}
        allowOverlap={false}
        preventCollision={false}
        onBreakpointChange={(newBreakpoint) => {
          setBreakpoint(newBreakpoint)
        }}
        onLayoutChange={(_, allLayouts) => {
          setLayouts(allLayouts)
        }}
        margin={[10, 10]}
      >
        {layouts?.[breakpoint]?.map((item) => {
          const RenderItem = renderItem({ item, editMode })
          if (!RenderItem) return null

          return (
            <GridItem
              uid={item.i}
              editable={edit_mode}
              key={item.i}
              onDelete={onDeleteItem}
              onEdit={onEditClicked}
              data-grid={item}
            >
              {RenderItem}
            </GridItem>
          )
        })}
      </ResponsiveReactGridLayout>
    )
  }, [layouts, editMode])

  return GridLayout
}

export const useLayout = (layout) => {
  const [layouts, setLayouts] = useState<Layouts>({})
  const [_layout, setLayout] = useState<ILayoutType>()
  const [changesMade, setChangesMade] = useState<boolean>(false)
  const callback = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const breakpoint = "lg"

  const fetchLayout = async () => {
    if (layout === undefined) {
      const newLayout = await createLayout()
      layout = newLayout
      callback.current?.("layout_changed", layout)
    }

    const lay = await getLayout(layout.uid)
    setLayout(lay)
    return
  }

  const createLayout = async () => {
    const layout = {
      columns: 120,
      row_height: 10,
    }
    return await addLayout(layout)
  }

  const saveLayout = async () => {
    if (!_layout) return
    _layout.elements.forEach(async (item) => {
      const ele = layouts[breakpoint].find((e) => e.i === item.widget)
      if (ele === undefined) {
        await deleteLayoutElement(layout.uid, item.uid)
      } else {
        await updateLayoutElement(layout.uid, item.uid, {
          ...item,
          x: ele.x,
          y: ele.y,
          w: ele.w,
          h: ele.h,
          is_static: ele.static,
        })
      }
    })
  }

  useEffect(() => {
    fetchLayout()
  }, [layout])

  useEffect(() => {
    if (!_layout) return
    const lays = cloneDeep(layoutsPatterns)
    Object.keys(lays).forEach((key) => {
      lays[key] = _layout.elements || []
      lays[key].forEach((item) => {
        item.i = item.widget
        item.uid = item.uid
      })
    })
    setLayouts(lays)
  }, [_layout])

  const addElement = async (uid, sizes: {}, breakpoint = "lg") => {
    if (!_layout) return
    const element = {
      i: uid,
      x: 0,
      y: _layout?.elements.length > 0 ? lowestPoint(_layout?.elements) + 1 : 0,
      w: 5,
      h: 5,
      ...sizes,
      static: false,
      breakpoint,
    }

    await addElementToLayout(layout?.uid, {
      ...element,
      widget: uid,
      is_static: element.static,
    })

    fetchLayout()
  }

  const removeElement = (uid, breakpoint = "lg") => {
    setLayouts((prev) => {
      return {
        ...prev,
        [breakpoint]: prev[breakpoint].filter((item) => item.i !== uid),
      }
    })
    setChangesMade(true)
  }

  const lowestPoint = (widgets: IWidgetElement[]): number => {
    return Math.max(...widgets.map((item) => item.y + item.h)) + 0
  }

  return {
    render: ({ ...props }) => (
      <GridLayout
        {...props}
        edit_mode={editMode}
        layouts={layouts}
        onDeleteItem={(ref) => {
          removeElement(ref.current.dataset.key)
        }}
        onEditClicked={(ref) => {
          callback.current?.("edit_clicked", ref.current.dataset.key)
        }}
        setLayouts={(layouts) => {
          setLayouts(layouts)
          setChangesMade(true)
        }}
      />
    ),
    saveChanges: () => (changesMade && editMode ? saveLayout() : undefined),
    setCallback: (cb) => {
      callback.current = cb
    },
    toggleEditMode: () => {
      setEditMode((prev) => !prev)
    },
    addElement,
    isEditMode: editMode,
    hasLayouts: layouts?.[breakpoint]?.length > 0,
    hasLayoutChanges: changesMade,
  }
}
