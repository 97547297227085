import { randomHexColor } from "@/utils/helpers"
import { Popover } from "antd"
import { useState } from "react"
import { SketchPicker } from "react-color"

interface IColorPickerInput {
  color?: string
  onSelect?(color: string): void
  button?({ open }: { open(state: boolean): void }): JSX.Element
}

export const ColorPickerMinimalInput = ({
  color = randomHexColor(),
  onSelect,
}: IColorPickerInput) => {
  const [_color, setColor] = useState<string>(color)

  const handleSelect = (result: any) => {
    const { hex } = result
    onSelect?.(hex)
    setColor(hex)
  }

  const toggleOpen = (state) => {
    if (state === true) return
    if (_color === undefined) return
    onSelect?.(_color)
  }

  return (
    <div
      onClick={() =>
        (state = true) =>
          toggleOpen(state)
        }
    >
      <Popover
        placement="right"
        trigger={"click"}
        onOpenChange={toggleOpen}
        content={
          <div className="flex flex-col gap-2">
            <SketchPicker color={_color} onChange={handleSelect} />
          </div>
        }
      >
        <div
          style={{ backgroundColor: _color }}
          className="h-3 w-3 rounded-full border border-dotted border-black"
        />
      </Popover>
    </div>
  )
}
