import { faTable } from "@awesome.me/kit-44b29310a6/icons/classic/regular"

export const SOURCE_TYPES = [
  {
    name: "Tasks",
    value: "tasks",
    icon: faTable,
  },
  {
    name: "RFQs",
    value: "rfqs",
    icon: faTable,
  },
  {
    name: "Contracts",
    value: "contracts",
    icon: faTable,
  },
  {
    name: "Talents",
    value: "talents",
    icon: faTable,
  },
] as const

export const DEFAULT_GROUP_KEYS = {
  tasks: [
    {
      uid: "created",
      label: "Created",
      type: "date",
      key: "created",
    },
    {
      uid: "_customer",
      label: "Customer",
      type: "customer",
      key: "_customer",
    },
  ],
  rfqs: [
    {
      uid: "created",
      label: "Created",
      type: "date",
      key: "created",
    },
    {
      uid: "_customer",
      label: "Customer",
      type: "customer",
      key: "_customer",
    },
    {
      uid: "_supplier",
      label: "Supplier",
      type: "supplier",
      key: "_supplier",
    },
  ],
  talents: [
    {
      uid: "month",
      label: "Month",
      type: "date",
      key: "month",
    },
  ],
  contracts: [
    {
      uid: "created",
      label: "Created",
      type: "date",
      key: "created",
    },
  ],
} as const

export const ALLOWED_GROUP_KEYS = {
  tasks: ["date", "customer"],
  rfqs: ["date", "customer", "supplier"],
  contracts: ["date"],
  talents: ["date"],
} as const

export const ALLOWED_TEMPLATE_FIELD_KEYS = {
  tasks: ["money", "number", "select", "uid"],
  rfqs: ["money", "number", "select", "uid"],
  contracts: ["money", "number", "select", "uid"],
  talents: ["money", "number", "select", "uid"],
} as const

export const DEFAULT_VALUE_FIELDS = {
  _interested: [
    { text: "Interested", uid: "true" },
    { text: "Not interested", uid: "false" },
    { text: "Pending", uid: "is_null" },
  ],
  status: [
    { text: "Hired", uid: "hired" },
    { text: "Potential", uid: "potential" },
    { text: "Extension", uid: "extension" },
  ],
}
export const DEFAULT_FIELDS = {
  tasks: [{ key: "uid", label: "ID", datasource: null, type: "uid" }],
  rfqs: [
    { key: "uid", label: "ID", datasource: null, type: "uid" },
    {
      key: "_interested",
      label: "Interest",
      datasource: { options: DEFAULT_VALUE_FIELDS._interested },
      type: "select",
    },
  ],
  contracts: [{ key: "uid", label: "ID", datasource: null, type: "uid" }],
  talents: [
    { key: "uid", label: "ID", datasource: null, type: "uid" },
    {
      key: "status",
      label: "Status",
      datasource: { options: DEFAULT_VALUE_FIELDS.status },
      type: "select",
    },
  ],
}
